.title {
    margin-bottom: var(--spacing-core-6);
}

.tags {
    display: flex;
    gap: var(--spacing-core-4);
    flex-wrap: wrap;
    margin-bottom: var(--spacing-core-4);
}
